import { gql } from '@apollo/client';

export const GET_PET_PARENTS = gql`
  query GetPetParentsTable(
    $onboardingStatus: String,
    $courseStatus: String,
    $animalType: [String],
    $careType: String,
    $daysAgo: Int,
    $smsStatus: String,
    $interests: String,
    $offset: Int,
    $limit: Int,
    $order: String,
    $orderBy: String,
    $searchText: String,
    $searchField: String,
    $onlyGroupByPetParent: Boolean,
  ) {
    petParents: petParents(
      onboardingStatus: $onboardingStatus,
      courseStatus: $courseStatus,
      animalType: $animalType,
      careType: $careType,
      daysAgo: $daysAgo,
      smsStatus: $smsStatus,
      interests: $interests,
      offset: $offset,
      limit: $limit,
      order: $order,
      orderBy: $orderBy,
      searchText: $searchText,
      searchField: $searchField,
      onlyGroupByPetParent: $onlyGroupByPetParent,
    ) {
      id
      firstName
      lastName
      email
      phoneNumber
      mailchimpSyncDate
      onboardDate
      hardBounceDate
      hardBounceResolvedDate
      thinkificId
      smsSyncDate
      smsOptOutDate
      name
      animalType
      careType
      caseNumber
      activatedAt
      completedAt
      animalCount
      interests
    }
    petParentCount: petParentCount(
      onboardingStatus: $onboardingStatus,
      courseStatus: $courseStatus,
      animalType: $animalType,
      careType: $careType,
      daysAgo: $daysAgo,
      smsStatus: $smsStatus,
      interests: $interests,
      searchText: $searchText,
      searchField: $searchField
      onlyGroupByPetParent: $onlyGroupByPetParent,
    ) {
      count
    }
  }
`;

export const GET_PET_PARENTS_WITH_COORDINATORS = gql`
    query GetPetParentsTable(
        $onboardingStatus: String,
        $courseStatus: String,
        $animalType: [String],
        $careType: String,
        $daysAgo: Int,
        $smsStatus: String,
        $interests: String,
        $offset: Int,
        $limit: Int,
        $order: String,
        $orderBy: String,
        $searchText: String,
        $searchField: String,
        $onlyGroupByPetParent: Boolean,
        $coordinatorIds: [String]
    ) {
        petParents: petParents(
            onboardingStatus: $onboardingStatus,
            courseStatus: $courseStatus,
            animalType: $animalType,
            careType: $careType,
            daysAgo: $daysAgo,
            smsStatus: $smsStatus,
            interests: $interests,
            offset: $offset,
            limit: $limit,
            order: $order,
            orderBy: $orderBy,
            searchText: $searchText,
            searchField: $searchField,
            onlyGroupByPetParent: $onlyGroupByPetParent,
            coordinatorIds: $coordinatorIds,
        ) {
            id
            firstName
            lastName
            email
            phoneNumber
            dateCreated
            mailchimpSyncDate
            onboardDate
            hardBounceDate
            hardBounceResolvedDate
            thinkificId
            smsSyncDate
            smsOptOutDate
            name
            animalType
            careType
            caseNumber
            activatedAt
            completedAt
            animalCount
            exitDate
            interests
            coordinator {
              id
              firstName
              lastName
            }
        }
        petParentCount: petParentCount(
            onboardingStatus: $onboardingStatus,
            courseStatus: $courseStatus,
            animalType: $animalType,
            careType: $careType,
            daysAgo: $daysAgo,
            smsStatus: $smsStatus,
            interests: $interests,
            searchText: $searchText,
            searchField: $searchField
            onlyGroupByPetParent: $onlyGroupByPetParent,
            coordinatorIds: $coordinatorIds
        ) {
            count
        }
    }
`;

export const GET_AWOS = gql`
  query GetAwos(
    $offset: Int,
    $limit: Int,
    $order: String,
    $orderBy: String,
    $filters: AwoSearchInput
  ) {
    awos: awos(
      offset: $offset,
      limit: $limit,
      order: $order,
      orderBy: $orderBy,
      filters: $filters
    ) {
      id
      name
      city
      state
      country
      website
      membershipType
      smsName
      kittenFoster
      catFoster
      dogFoster
      catAdopt
      dogAdopt
      members
      status
      lastSyncDate
    }
    awosCount: awosCount(filters: $filters){
      count
    }
  }
`;

export const GET_AWO = gql`
  query GetAwo {
    awo {
      id
      dateCreated
      name
      smsName
      kittenFoster
      catFoster
      dogFoster
      catAdopt
      dogAdopt
      twilioPhoneNumber
      preSurrenderDashboard
      coSheltering
      coShelteringCoordinators
      country
      resourcesUrl
      membershipType
      timezone
      taskSettings {
        id
        feedback1
        feedback2
        feedback3
        feedback4
        feedback5
        asksQuestion
        emailTypo
        phoneTypo
        adoptionReview
        fosterReview
        unresponsive
        completedSupport
      }
      awoFlyers {
        pdfMonkeyUrl {
          url
        }
      }
    }
  }
`;

export const GET_SINGLE_AWO = gql`
  query GetSingleAwo($id: Int) {
    awo(id: $id) {
      id
      name
      smsName
      kittenFoster
      catFoster
      dogFoster
      catAdopt
      dogAdopt
      twilioPhoneNumber
      preSurrenderDashboard
      membershipType
      displayName
      note
      website
      city
      state
      zipCode
      country
      timezone
      orgType
      resourcesUrl
      automationEnabled
      status
      failureReason
      lastSyncDate
      dateUpdated
      animalsUpdated
    }
  }
`;

export const GET_AWO_USERS = gql`
  query GetAwoUsers(
    $awoId: Int!,
    $order: String,
    $orderBy: String,
    $name: String,
    $onlyReviewers: Boolean,
  ) {
    awoUsers(awoId: $awoId, order: $order, orderBy: $orderBy, name: $name, onlyReviewers: $onlyReviewers) {
      id
      awoId
      firstName
      lastName
      email
      inviteDate
      lastLogin
      lastTaskAction
      adoptionNotificationEmail
      fosterNotificationEmail
      preSurrenderNotificationEmail
      taskNotificationsEmail
      ownClientNotificationEmail
    }
  }
`;

export const GET_ANIMALS = gql`
  query GetAnimals($awoId: Int) {
    animals(awoId: $awoId) {
      id
      name
      awoName
      animalMedias {
        id
        fileType
        url
        isPrimary
      }
    }
  }
`;

export const GET_COURSE_STATS = gql`
  query GetCourseStats(
    $animalType: String,
    $careType: String,
    $startDaysAgo: Int,
    $midDaysAgo: Int,
    $endDaysAgo: Int
  ) {
    current: courseStats(
      animalType: $animalType,
      careType: $careType,
      startDaysAgo: $midDaysAgo,
      endDaysAgo: $endDaysAgo
    ) {
      invitedCount
      onboardedCount
    }
    previous: courseStats(
      animalType: $animalType,
      careType: $careType,
      startDaysAgo: $startDaysAgo,
      endDaysAgo: $midDaysAgo
    ) {
      invitedCount
      onboardedCount
    }
  }
`;

export const GET_PET_PARENT = gql`
  query GetPetParent($id: Int!) {
    petParent(id: $id) {
      id
      firstName
      lastName
      email
      phoneNumber
      city
      country
      state
      streetAddress
      zipCode
      smsOptOutDate
      unsubscribeDate
      purchaseDate
      numLogins
      lastLoginDate
      smsPreferred
      startedDaysAgo
      dateStarted
      dateCancelled
      animalMedias {
        id
        dateCreated
        fileType
        url
      }
      awo {
        name
        membershipType
        smsName
      }
      animals {
        id
        dateCreated
        name
        animalType
        careType
        gender
        primaryBreed
        birthDate
        exitDate
        caseNumber
        externalId
        awo {
          name
          preSurrenderDashboard
          smsName
        }
        animalMedias {
          id
          dateCreated
          fileType
          url
          pdfMonkeyUrl {
            url
          }
          isPrimary
        }
      }
      enrollments {
        courseName
      }
      orders {
        productId
        product {
          name
        }
      }
      activeConversation {
        id
        isActive
        dateCreated
        animal {
          name
          careType
          exitDate
        }
      }
    }
  }
`;

export const GET_TYPEFORM_SINGULAR = gql`
  query GetTypeformForm($id: String!) {
    typeformForm(id: $id) {
      id
      title
      createdAt
      lastUpdatedAt
      fields {
        id
        title
      }
    }
  }
`;

export const GET_TYPEFORM = gql`
  query GetTypeformForm($id: String!, $query: String!) {
    typeformForm: typeformForm(id: $id) {
      fields {
        id
        title
      }
    }
    typeformResponse: typeformResponse(id: $id, query: $query) {
      items {
        answers {
          ... on NumberField {
            field {
              id
              type
            }
            number
            type
          }
          ... on ChoicesField {
            field {
              id
              type
            }
            choices {
              labels
            }
            type
          }
          ... on ChoiceField {
            field {
              type
              id
            }
            choice {
              label
            }
            type
          }
          ... on BooleanField {
            field {
              id
              type
            }
            boolean
            type
          }
        }
        hidden {
          awo
          country
          name
          email
          onboarded
          referral
        }
      }
    }
  }
`;

export const GET_TYPEFORM_FORMS = gql`
  query GetTypeformForms {
    typeformForms {
      id
      typeformId
      createdAt
      lastUpdatedAt
      typeformTitle
      responseTitle
      latestVersion
      questions {
        id
        title
        fieldType
      }
    }
  }
`;

export const ADD_TYPEFORM_FORM = gql`
  mutation AddTypeformForm($id: String!, $title: String!) {
    addTypeformForm(id: $id, title: $title)
  }
`;

export const UPDATE_TASK = gql`
  mutation UpdateTask($id: String!, $completed: Boolean!) {
    updateTask(id: $id, completed: $completed)
  }
`;

export const UPDATE_EMAIL = gql`
  mutation UpdateEmail($oldEmail: String!, $newEmail: String!) {
    updateEmail(oldEmail: $oldEmail, newEmail: $newEmail)
  }
`;

export const UPDATE_PHONE_NUMBER = gql`
  mutation UpdatePhoneNumber($email: String!, $newPhone: String!) {
    updatePhoneNumber(email: $email, newPhone: $newPhone)
  }
`;

export const UPDATE_PET_PARENT_ANIMAL = gql`
  mutation UpdatePetParentAnimal($oldData: JSON!, $newData: JSON!) {
    updatePetParentAnimal(oldData: $oldData, newData: $newData)
  }
`;

export const STOP_SUPPORT_UNSUBSCRIBE = gql`
  mutation StopSupportUnsubscribe($email: String!, $actionerEmail: String!, $action: String!, $newStartDate: String) {
    stopSupportUnsubscribe(email: $email, actionerEmail: $actionerEmail, action: $action, newStartDate: $newStartDate)
  }
`;

export const GET_TYPEFORM_RESPONSES = gql`
  query GetTypeformResponses($ppId: String!) {
    responses: response(ppId: $ppId) {
      id
      typeformId
      submittedAt
      title
      answers {
        id
        text
        question
      }
    }
    questions: questions(ppId: $ppId) {
      id
      dateUpdated
      comments {
        id
        authorId
        htmlBody
        medias {
          url
          fileType
        }
      }
    },
    updates: update(ppId: $ppId) {
      id
      dateUpdated
      title
      subtitle
      answers {
        id
        text
        question
      }
    }
  }
`;

export const GET_TICKET = gql`
  query GetTicket($search: TicketSearchInput) {
    question(search: $search) {
      id
      ticketId
      subject
      dateUpdated
      reviewDate
      flagDate
      petParent {
        id
        firstName
      }
      comments {
        id
        authorId
        htmlBody
        medias {
          url
          fileType
        }
      }
      tags {
        id
        value
        deleted
      }
      reviewedBy {
        id
        firstName
      }
    }
    nextQuestion(search: $search) {
      ticketId
    }
    questionCount: questionCount(search: $search) {
      count
    }
  }
`;

export const GET_PRE_SURRENDER_DASHBOARD_STATS = gql`
  query GetPreSurrenderDashboardStats($careType: String) {
    taskCount: taskCount(completed: false, careType: $careType) {
      count
    }
    totalSupportStats: supportStats(startDaysAgo: 30, endDaysAgo: 0) {
      invitedCount
      optOutCount
      responsiveCount
    }
    totalPreviousMonthSupportStats: supportStats(startDaysAgo: 60, endDaysAgo: 30) {
      invitedCount
      optOutCount
      responsiveCount
    }
    dogSupportStats: supportStats(animalType: "Dog", startDaysAgo: 30, endDaysAgo: 0) {
      invitedCount
    }
    previousMonthDogSupportStats: supportStats(animalType: "Dog", startDaysAgo: 60, endDaysAgo: 30) {
      invitedCount
    }
    catSupportStats: supportStats(animalType: "Cat", startDaysAgo: 30, endDaysAgo: 0) {
      invitedCount
    }
    previousMonthCatSupportStats: supportStats(animalType: "Cat", startDaysAgo: 60, endDaysAgo: 30) {
      invitedCount
    }
    currentMonthEngagementStats: engagementStats(startDaysAgo: 30, endDaysAgo: 0) {
      totalEngaged
    }
    previousMonthEngagementStats: engagementStats(startDaysAgo: 60, endDaysAgo: 30) {
      totalEngaged
    }
  }
`;

export const GET_CO_SHELTERING_DASHBOARD_STATS = gql`
  query GetPreSurrenderDashboardStats($careType: String) {
    taskCount: taskCount(completed: false, careType: $careType) {
      count
    }
    totalSupportStats: supportStats(startDaysAgo: 30, endDaysAgo: 0) {
      invitedCount
      optOutCount
      responsiveCount
    }
  }
`;

export const GET_DASHBOARD_STATS = gql`
  query GetDashboardStats($careType: String) {
    taskCount: taskCount(completed: false, careType: $careType) {
      count
    }
    currentMonthEngagementStats: engagementStats(startDaysAgo: 60, endDaysAgo: 30) {
      adoptersEngaged
      fostersEngaged
    }
    previousMonthEngagementStats: engagementStats(startDaysAgo: 90, endDaysAgo: 60) {
      adoptersEngaged
      fostersEngaged
    }
    supportStats: supportStats(startDaysAgo: 30, endDaysAgo: 0) {
      invitedCount
      optOutCount
    }
    previousMonthSupportStats: supportStats(startDaysAgo: 60, endDaysAgo: 30) {
      invitedCount
      optOutCount
    }
    nps {
      score
    }
  }
`;

export const GET_DASHBOARD = gql`
  query GetDashboard {
    taskCount: taskCount(completed: false) {
      count
    }
    newsfeedCount {
      count
    }
    awoUser {
      email
      adoptionNotificationEmail
      fosterNotificationEmail
      preSurrenderNotificationEmail
    }
  }
`;

export const GET_NEWSFEED = gql`
  query GetNewsfeed($offset: Int!, $limit: Int!) {
    newsfeed(offset: $offset, limit: $limit) {
      id
      dateUpdated
      actionText
      petParent {
        id
        firstName
        lastName
        animals {
          name
          careType
          dateCreated
          animalType
        }
      }
      question {
        id
      }
    }
  }
`;

export const GET_TASKS = gql`
  query GetTasks(
    $offset: Int!,
    $limit: Int!,
    $completed: Boolean,
    $startDaysAgo: Int,
    $endDaysAgo: Int,
    $careType: String,
    $coordinatorIds: [String]
  ) {
    tasks: tasks(
      offset:$offset,
      limit: $limit,
      completed: $completed,
      startDaysAgo: $startDaysAgo,
      endDaysAgo: $endDaysAgo,
      careType: $careType,
      coordinatorIds: $coordinatorIds
    ) {
      id
      dateUpdated
      dateCreated
      actionText
      nextStep
      completed
      petParent {
        id
        firstName
        lastName
        email
        animals {
          name
          animalType
          careType
          dateCreated
        }
      }
    }
    taskCount: taskCount(
      completed: $completed,
      startDaysAgo: $startDaysAgo,
      endDaysAgo: $endDaysAgo,
      careType: $careType,
      coordinatorIds: $coordinatorIds
    ) {
      count
    }
  }
`;

export const GET_BEHAVIORAL_ISSUES = gql`
  query GetFeedback($feedbackDaysAgo: Int) {
    awoAdoptedDogBehavioralIssues: behavioralIssues(
      typeformFormId: 46,
      careType: "Adopted",
      invert: 0,
      daysAgo: $feedbackDaysAgo
    ) {
      numResponses
      issues
    }
    otherAdoptedDogBehavioralIssues: behavioralIssues(
      typeformFormId: 46,
      careType: "Adopted",
      invert: 1,
      daysAgo: $feedbackDaysAgo
    ) {
      numResponses
      issues
    }
    awoFosterDogBehavioralIssues: behavioralIssues(
      typeformFormId: 46,
      careType: "Foster",
      invert: 0,
      daysAgo: $feedbackDaysAgo
    ) {
      numResponses
      issues
    }
    otherFosterDogBehavioralIssues: behavioralIssues(
      typeformFormId: 46,
      careType: "Foster",
      invert: 1,
      daysAgo: $feedbackDaysAgo
    ) {
      numResponses
      issues
    }
    awoAdoptedCatBehavioralIssues: behavioralIssues(
      typeformFormId: 47,
      careType: "Adopted",
      invert: 0,
      daysAgo: $feedbackDaysAgo
    ) {
      numResponses
      issues
    }
    otherAdoptedCatBehavioralIssues: behavioralIssues(
      typeformFormId: 47,
      careType: "Adopted",
      invert: 1,
      daysAgo: $feedbackDaysAgo
    ) {
      numResponses
      issues
    }
    awoFosterCatBehavioralIssues: behavioralIssues(
      typeformFormId: 47,
      careType: "Foster",
      invert: 0,
      daysAgo: $feedbackDaysAgo
    ) {
      numResponses
      issues
    }
    otherFosterCatBehavioralIssues: behavioralIssues(
      typeformFormId: 47,
      careType: "Foster",
      invert: 1,
      daysAgo: $feedbackDaysAgo
    ) {
      numResponses
      issues
    }
  }
`;

export const GET_FEEDBACK = gql`
  query GetFeedback {
    taskCount(completed: false) {
      count
    }
    behavioralIssues {
      numResponses
    }
  }
`;

export const GET_CAMPAIGNS = gql`
  query GetCampaigns {
    campaigns {
      id
      name
      sendHour
      messageTemplates {
        id
        campaignId
        text
        position
        sendDelay
        note
        numericalResponse
      }
      vMessageTemplate @client
    }
  }
`;

export const GET_AWO_REPORTS = gql`
  query GetAwoReports($offset: Int, $limit: Int, $order: String, $orderBy: String) {
    awoReports(offset: $offset, limit: $limit, order: $order, orderBy: $orderBy) {
      id
      dateCreated
      documentId
      pdfMonkeyUrl {
        url
      }
    }
    awoReportsCount {
      count
    }
  }
`;

export const GET_TICKETS_TO_REVIEW = gql`
  query GetTicketsToReview {
    ticketsToReview {
      tagName
      unreviewedCount
      firstQuestion {
        ticketId
      }
    }
    questionCount {
      count
    }
  }
`;

export const CREATE_AWO = gql`
  mutation CreateAwo($awo: AwoInput!) {
    createAwo(awo: $awo) {
      code
      success
      message
      awo {
        id
        name
      }
      awoUser {
        id
      }
    }
  }
`;

export const UPDATE_AWO = gql`
  mutation UpdateAwo($id: ID!, $awo: AwoInput!) {
    updateAwo(id: $id, awo: $awo) {
      code
      success
      message
      awo {
        id
        name
        smsName
        kittenFoster
        catFoster
        dogFoster
        catAdopt
        dogAdopt
        twilioPhoneNumber
        preSurrenderDashboard
        membershipType
        displayName
        note
        website
        city
        state
        zipCode
        country
        timezone
        orgType
        resourcesUrl
        status
        failureReason
        lastSyncDate
        animalsUpdated
        dateUpdated
      }
    }
  }
`;

export const CREATE_AWO_USER = gql`
  mutation CreateAwoUser($awoUser: AwoUserInput!) {
    response: createAwoUser(awoUser: $awoUser) {
      code
      success
      message
      awoUser {
        id
      }
    }
  }
`;

export const UPDATE_AWO_USER = gql`
  mutation UpdateAwoUser($id: String!, $awoUser: AwoUserInput!) {
    response: updateAwoUser(id: $id, awoUser: $awoUser) {
      code
      success
      message
      awoUser {
        id
        email
        firstName
        lastName
        adoptionNotificationEmail
        fosterNotificationEmail
        preSurrenderNotificationEmail
        taskNotificationsEmail
        ownClientNotificationEmail
        inviteDate
        lastLogin
        lastTaskAction
      }
    }
  }
`;

export const REINVITE_AWO_USER = gql`
  mutation ReinviteAwoUser($id: String!, $awoUser: AwoUserInput!) {
    response: reinviteAwoUser(id: $id, awoUser: $awoUser) {
      code
      success
      message
      awoUser {
        id
        email
        firstName
        lastName
        adoptionNotificationEmail
        fosterNotificationEmail
        preSurrenderNotificationEmail
        taskNotificationsEmail
        ownClientNotificationEmail
        inviteDate
        lastLogin
      }
    }
  }
`;

export const DELETE_AWO_USER = gql`
  mutation DeleteAwoUser($id: String!, $awoName: String!) {
    response: deleteAwoUser(id: $id, awoName: $awoName) {
      code
      success
      message
      awoUser {
        id
        email
        firstName
        lastName
        adoptionNotificationEmail
        fosterNotificationEmail
        preSurrenderNotificationEmail
        taskNotificationsEmail
        ownClientNotificationEmail
        inviteDate
        lastLogin
      }
    }
  }
`;

export const UPDATE_MESSAGE_TEMPLATE = gql`
  mutation UpdateMessageTemplate($id: String!, $messageTemplate: MessageTemplateInput!) {
    response: updateMessageTemplate(id: $id, messageTemplate: $messageTemplate) {
      code
      success
      message
      messageTemplate {
        id
        text
        position
        sendDelay
        note
        numericalResponse
      }
    }
  }
`;

export const CREATE_MESSAGE_TEMPLATE = gql`
  mutation CreateMessageTemplate($messageTemplate: MessageTemplateInput!) {
    response: createMessageTemplate(messageTemplate: $messageTemplate) {
      code
      success
      message
      messageTemplate {
        id
        text
        position
        sendDelay
        note
        numericalResponse
      }
    }
  }
`;

export const DELETE_MESSAGE_TEMPLATE = gql`
  mutation DeleteMessageTemplate($id: String!) {
    response: deleteMessageTemplate(id: $id) {
      code
      success
      message
    }
  }
`;

export const UPDATE_GLOBAL_SETTINGS = gql`
  mutation UpdateGlobalSettings($hourToSend: String!) {
    message: updateGlobalSettings(hourToSend: $hourToSend)
  }
`;

export const UPDATE_TICKET = gql`
  mutation UpdateTicket($id: String!, $ticket: TicketInput!) {
    response: updateTicket(id: $id, ticket: $ticket) {
      code
      success
      message
    }
  }
`;

export const GET_USER = gql`
  query GetDashboard {
    taskCount: taskCount(completed: false) {
      count
    }
    newsfeedCount {
      count
    }
  }
`;

export const GET_MESSAGES = gql`
  query GetMessages($petParentId: String!) {
    messages(petParentId: $petParentId) {
      id
      dateSent
      text
      direction
    }
  }
`;
