import React, { useEffect } from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import {
 Autocomplete, Box, Button, FormHelperText, Snackbar, TextField, Typography,
} from '@mui/material';
import { isEqual } from 'lodash';
import { grey } from '@mui/material/colors';
import { Control, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';
import { AutocompleteControl } from '../FormControls';
import { generateToken } from '../../utilities';

const managementSystemsOptions = [
  {
    label: 'Animal Shelter Manager',
    value: 'Animal Shelter Manager',
  }, {
    label: 'Animals First',
    value: 'Animals First',
  }, {
    label: 'Chameleon',
    value: 'Chameleon',
  }, {
    label: 'Pawlytics',
    value: 'Pawlytics',
  }, {
    label: 'Petpoint',
    value: 'Petpoint',
  }, {
    label: 'Petstablished',
    value: 'Petstablished',
  }, {
    label: 'ShelterBuddy',
    value: 'Shelter Buddy',
  }, {
    label: 'ShelterLuv',
    value: 'Shelterluv',
  }, {
    label: 'Other',
    value: 'Other',
  },
];

export const shelterManagementSchema = {
  smsName: yup.object({
    label: yup.string(),
    value: yup.string(),
  }).required('Select a management system'),
  token: yup.string().when('smsName', ([smsName], tokenSchema) => {
    if (smsName?.value === 'Animal Shelter Manager' || smsName?.value === 'Animals First') {
      return tokenSchema.required('Token is required');
    }
    return tokenSchema;
  }),
  apiUrl: yup.string().when('smsName', ([smsName], apiUrlSchema) => {
    if (smsName?.value === 'Shelter Buddy') {
      return apiUrlSchema.required('API URL is required');
    }
    return apiUrlSchema;
  }),
  apiKey: yup.string().when('smsName', ([smsName], apiKeySchema) => {
    if (smsName?.value === 'Shelterluv' || smsName?.value === 'Shelter Buddy') {
      return apiKeySchema.required('API key is required');
    }
    if (smsName?.value === 'Pawlytics') {
      return apiKeySchema.required('Organization ID is required');
    }
    return apiKeySchema;
  }),
};

function ShelterManagementPartial(
{
 control, errors, setValue, getValues, onboarding = true, defaultValues,
}:
    {
      control: Control<any, any>,
      errors: FieldErrors<{ smsName: {
          label: string;
          value: string;
        } | null }>,
      setValue: (field: any, value: any) => void,
      getValues: (key: string) => string,
      onboarding?: boolean,
      defaultValues?: Record<string, any>,
     },
) {
  const [selectedSystem, setSelectedSystem] = React.useState<{ label: string, value: string } | null>(null);
  const tokenRef = React.useRef<string>(generateToken());
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  useEffect(() => {
      const system = managementSystemsOptions.find((option) => option.value === defaultValues?.smsName);
      if (system) {
        setValue('smsName', system);
      }
  }, [defaultValues?.smsName, setValue]);

  const googleDocButton = (
    <Button
      color="secondary"
      variant="outlined"
      onClick={() => window.open('https://docs.google.com/document/d/1Kp5dFlvSS58vDml8p6IioPYvHul-jUYfoPFc8opwj-4/edit?usp=sharing', '_blank')}
    >
      Open google doc
    </Button>
  );

  const showManualSetupMessage = selectedSystem?.value === 'Other'
    || selectedSystem?.value === 'Petstablished';

  const showPet24Message = selectedSystem?.value === 'Petpoint'
    || selectedSystem?.value === 'Chameleon';

  return (
    <>
      <AutocompleteControl
        fieldName="smsName"
        control={control}
        render={({ onChange, ...props }) => (
          <>
            <Autocomplete
              onChange={
                (event, value) => {
                  onChange({ target: { name: 'smsName', value } });
                  setSelectedSystem(value);
                  if (!value) {
                    setValue('token', '');
                    setValue('apiUrl', '');
                  }
                  if (value.value === 'Animal Shelter Manager' || value.value === 'Animals First') {
                    setValue('token', tokenRef.current);
                    setValue('apiUrl', '');
                  } else {
                    setValue('token', '');
                    if (value.value === 'Shelterluv') {
                      setValue('apiUrl', 'https://shelterluv.com/api/v1');
                    } else if (value.value === 'Pawlytics') {
                      setValue('apiUrl', 'https://api.pawlytics.com/api/graphql');
                    } else {
                      setValue('apiUrl', '');
                    }
                  }
                }
              }
              {...props}
              options={managementSystemsOptions}
              defaultValue={defaultValues?.smsName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  placeholder="Select software"
                  label="Software"
                />
              )}
              isOptionEqualToValue={(...comp) => isEqual(...comp)}
              color={errors.smsName ? 'error' : 'primary'}
            />
            {errors.smsName && (
              <FormHelperText error>{errors.smsName?.message}</FormHelperText>
            )}
          </>
        )}
      />
      {
        (selectedSystem?.value === 'Animal Shelter Manager' || selectedSystem?.value === 'Animals First') && onboarding && (
          <Box sx={{ bgcolor: grey[50], padding: '16px', marginTop: '16px' }}>
            <Box marginBottom="24px">
              <Typography marginBottom="8px" variant="overline">Next steps</Typography>
              <Typography marginBottom="8px" variant="subtitle2">
                1. Copy the new token for this
                shelter
              </Typography>
              <Typography
                marginBottom="8px"
                color="secondary.text"
                variant="body2"
              >
                {getValues('token')}
              </Typography>
              <Button
                color="secondary"
                onClick={() => {
                  navigator.clipboard.writeText(getValues('token'));
                  setOpenSnackbar(true);
                }}
                variant="contained"
              >
                Copy token
              </Button>
            </Box>
            <Box>
              <Typography marginBottom="8px" variant="subtitle2">
                2. Follow the instructions on this document to
                share the token with the AWO
              </Typography>
              {googleDocButton}
            </Box>
          </Box>
        )
      }
      {
        selectedSystem?.value === 'Pawlytics' && (
          <>
            {
              onboarding && (
                <Box sx={{ bgcolor: grey[50], padding: '16px', marginTop: '16px' }}>
                  <Typography marginBottom="8px" variant="overline">Next steps</Typography>
                  <Typography marginBottom="8px" variant="subtitle2">
                    Follow the instructions on this document to get
                    the required information
                  </Typography>
                  {googleDocButton}
                </Box>
              )
            }
            <TextFieldElement
              name="apiKey"
              sx={{ marginTop: '16px' }}
              fullWidth
              label="Organization ID"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </>
        )
      }
      {
        selectedSystem?.value === 'Shelter Buddy' && (
          <>
            {
              onboarding && (
                <Box sx={{ bgcolor: grey[50], padding: '16px', marginTop: '16px' }}>
                  <Typography marginBottom="8px" variant="overline">Next steps</Typography>
                  <Typography marginBottom="8px" variant="subtitle2">
                    Follow the instructions on this document to get
                    the required information
                  </Typography>
                  {googleDocButton}
                </Box>
              )
            }
            <TextFieldElement
              name="apiUrl"
              sx={{ marginTop: '16px' }}
              fullWidth
              label="API URL"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextFieldElement
              name="apiKey"
              sx={{ marginTop: '16px' }}
              fullWidth
              label="API key"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </>
        )
      }
      {
        selectedSystem?.value === 'Shelterluv' && (
          <>
            {
              onboarding && (
                <Box sx={{ bgcolor: grey[50], padding: '16px', marginTop: '16px' }}>
                  <Typography marginBottom="8px" variant="overline">Next steps</Typography>
                  <Typography marginBottom="8px" variant="subtitle2">
                    Follow the instructions on this document to get
                    the required information
                  </Typography>
                  {googleDocButton}
                </Box>
              )
            }
            <TextFieldElement
              name="apiKey"
              sx={{ marginTop: '16px' }}
              fullWidth
              label="API key"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </>
        )
      }
      {
        showManualSetupMessage && onboarding && (
          <Box sx={{
            bgcolor: 'alert.warningFill', padding: '16px', marginTop: '16px', borderRadius: '4px',
          }}
          >
            <Typography color="warning.main" variant="subtitle2">
              Because this organization doesn&apos;t use a
              shelter management system supported by Petcademy, they&apos;ll need to manually invite each pet
              parent.
            </Typography>
          </Box>
        )
      }
      {
        showPet24Message && onboarding && (
          <Box sx={{
            bgcolor: 'alert.warningFill', padding: '16px', marginTop: '16px', borderRadius: '4px',
          }}
          >
            <Typography color="warning.main" variant="subtitle2">
              24Pet will automatically send us the data for this organization. There is nothing you need to do.
            </Typography>
          </Box>
        )
      }
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setOpenSnackbar(false)}
        message="Token copied"
      />
    </>
  );
}
export default ShelterManagementPartial;
