import React from 'react';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography, Snackbar, Alert,
} from '@mui/material';
import {
  Search, Close,
} from '@mui/icons-material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import OrganizationsTable from '../OrganizationsTable';
import NewOrganizationModal from './NewOrganizationModal';
import SmallScreenPlaceholder from '../SmallScreenPlaceholder';
import countriesOptions from '../../resources/countriesData';
import FiltersDialog from './FiltersDialog';
import usePagination from './usePagination';
import useFilters from './useFilters';
import useOrganization from './useOrganization';

function OrganizationsContainer() {
  const pagination = usePagination();
  const [open, setOpen] = React.useState(false);
  const filters = useFilters({ setPage: pagination.setPage, setOpen });
  const sorting = React.useMemo(() => ({
    order: pagination.order,
    orderBy: pagination.orderBy,
  }), [pagination.order, pagination.orderBy]);
  const [searchText, setSearchText] = React.useState('');
  const {
    organizations,
    loading,
    error,
    networkStatus,
  } = useOrganization(
    {
      filters,
      pagination,
      sorting,
      searchText,
    },
  );

  const [newOrganizationDialog, setNewOrganizationDialog] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  if (error) throw error;

  return (
    <Box sx={{
      minHeight: '100vh',
    }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 3,
        borderBottom: 1,
        borderColor: 'primary.50',
      }}
      >
        <Typography variant="overline">Organizations</Typography>
        <Button
          onClick={() => setNewOrganizationDialog(true)}
          variant="text"
          size="small"
        >
          New organization
        </Button>
      </Box>
      <Box sx={{ padding: 3 }}>
        <Grid container>
          <Grid
            item
            xs={0}
            lg={12}
            sx={{
              display: {
                xs: 'none',
                md: 'block',
              },
            }}
          >
            <Grid container>
              <Grid item xs={12} sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    gap={2}
                  >
                    <Box width="30%" maxWidth="300px">
                      <TextField
                        fullWidth
                        margin="normal"
                        name="text-search"
                        label="Search by name"
                        type="text"
                        id="text-search"
                        variant="standard"
                        value={searchText}
                        onChange={(e) => {
                          pagination.setPage(0);
                          setSearchText(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton aria-label="search">
                                <Search />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    gap={1}
                  >
                    {pagination.order === undefined && pagination.orderBy === null ? (null) : (
                      <Box>
                        <Button
                          startIcon={<Close />}
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            pagination.setOrder(undefined);
                            pagination.setOrderBy(null);
                          }}
                        >
                          Clear Sorting
                        </Button>
                      </Box>
                    )}
                    <Box>
                      {(filters.applied.status.length > 0
                        || filters.applied.smsName.length > 0
                        || filters.applied.country.length > 0
                        || filters.applied.state.length > 0
                      ) ? (
                        <Button
                          startIcon={<Close />}
                          onClick={filters.handleResetFilters}
                          variant="text"
                          size="small"
                        >
                          Clear filters
                        </Button>
                      ) : (
                        <Button
                          startIcon={<FilterAltOutlinedIcon />}
                          onClick={() => setOpen(true)}
                          variant="text"
                          size="small"
                        >
                          Filters
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <OrganizationsTable
                  pagination={pagination}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  count={organizations.count}
                  loading={loading}
                  organizations={organizations.list}
                  networkStatus={networkStatus}
                />
              </Grid>
            </Grid>
          </Grid>
          <SmallScreenPlaceholder />
        </Grid>
      </Box>
      <FiltersDialog
        open={open}
        filters={filters}
        locationOptions={{
          countries: countriesOptions,
          states: filters.getAvailableStates(filters.selectedCountries),
        }}
      />
      <Dialog fullScreen open={newOrganizationDialog}>
        <NewOrganizationModal
          onClose={(awoName?: string) => {
            setNewOrganizationDialog(false);
            if (awoName) {
              setSnackbarMessage(`AWO: ${awoName} was successfully created`);
              setSnackbarOpen(true);
            }
          }}
        />
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        key={snackbarMessage}
      >
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>
    </Box>
  );
}

export default OrganizationsContainer;
